<template>
  <div
    v-if="!isLoading"
    class="contentblocksPageContainer"
  >
    <ContentBlocksContainer :data="data" />
  </div>
</template>

<script setup lang="ts">
import useElocusStore from '~/store/elocus';

const elocusStore = useElocusStore();

const data = ref<any>({});
const route = useRoute();
const isLoading = ref(true);

const slug = elocusStore.slugsDatabase[route.path];

if (slug) {
  data.value = await fetchData(`/api/page/${slug}`);
  isLoading.value = false;
  setMetaData(data.value.metadata);
} else {
  const router = useRouter();
  router.push('/error');
  if (process.server) {
    const event = useRequestEvent();
    setResponseStatus(event, 404);
  }
}
</script>

<style lang="scss" scoped>
.index {
  padding-top: 80px;
}
</style>
